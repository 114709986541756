export default {
  methods: {
    ongDateFormat(date, format = null) {
      if (date) {
        if (format == "datetime")
          return new Date(date).toLocaleString("ru-RU", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          });
        else return new Date(date).toLocaleString("ru-RU", { year: "numeric", month: "numeric", day: "numeric" });
      } else return date;
    },
  },
};
export const $t = (key) => {
  let content_words = JSON.parse(localStorage.getItem("phrases")) ?? [];
  content_words.find((v) => {
    if (v.word.trim() === key) {
      key = v.translation;
    }
  });
  return key;
};
